$body-bg:                       #f9f9f9;
$brand-dark:                    #556b7c;
$brand-red:                     #ce2127;
$brand-orange:                  #dc651e;
$brand-blue:                    #2d73d0;
$brand-gray:                    #e2e2e2;
$gray-text:                     #c7c7c7;
$dark-gray:                     #717171;
$gray-icon:                     #9ea3b1;
$white:                         #FFFFFF;
$black:                         #000000;
$modal-backdrop-bg:             rgba(0,0,0,0.5);
$male-icon:                     #2b83e6;
$female-icon:                   #e62b8c;
$facebook-color:                #1877F2;
$twitter-color:                 #000000	;
$google-color:                  #EA4335;
$dating-badge-bg:               #f7f1f7;
$dating-badge-color:            #531955;
//$sidebar-mail-plus:             #9d2136;
//$sidebar-files-plus:            #7b4183;

$input-border:                  $gray-icon;
$sidebar-border:                #6d8090;
$input-border-focus:            $brand-dark;
$dropdown-dark-bg:              darken($brand-dark, 5%);
$dropdown-link-color:           $white;
$dropdown-link-hover-color:     $white;
$dropdown-link-hover-bg:        $brand-dark;
$dropdown-link-active-bg:       $brand-dark;
$btn-border-bottom:             rgba(0,0,0,0.45);

$brand-primary:         darken(#428bca, 6.5%); // #337ab7
$brand-success:         #5cb85c;
$brand-info:            #def2f8;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;
$brand-secondary:       $brand-gray;
$brand-light:           #f8f9fa;
