//******************************************************************************
// Override Bootstrap variables here (defaults from bootstrap-sass v3.3.5):
//******************************************************************************

//core colors
@import "../tb/core_colors"

$bootstrap-sass-asset-helper: false !default
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000
$gray-darker:            lighten($gray-base, 13.5%) // #222
$gray-dark:              lighten($gray-base, 20%)   // #333
$gray:                   lighten($gray-base, 33.5%) // #555
$gray-light:             lighten($gray-base, 46.7%) // #777
$gray-lighter:           lighten($gray-base, 93.5%) // #eee

$danger:                $brand-danger
$primary:               $brand-dark !default
$secondary:             $brand-gray !default

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff
//** Global text color on `<body>`.
$text-color:            $gray-dark

//** Global textual link color.
$link-color:            #2a6887
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%)
//** Link hover decoration.
$link-hover-decoration: none


//== Typography
$font-family-base: ProximaNova, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default

$font-size-base:          1rem !default
$font-size-large:         ceil(($font-size-base * 1.25)) // ~18px
$font-size-small:         ceil(($font-size-base * 0.7)) // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) // ~24px
$font-size-h4:            $font-size-base + 0.25rem // ~18px
$font-size-h5:            $font-size-base
$font-size-h6:            ceil(($font-size-base * 0.85)) // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.5 !default
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base))

$icon-font-name:          "shared-icons"

$border-radius-base:    0px
$border-radius-large:   5px
$border-radius-small:   2px

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    $text-color
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $gray-lighter

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

// $btn-font-weight:                normal

$btn-default-color:                 $gray-dark
$btn-default-bg:                    darken($gray-lighter, 5%)
$btn-default-border:                #c1c1c1

$btn-primary-color:                 #fff
$btn-primary-bg:                    #556b7c

$btn-primary-border:                darken($btn-primary-bg, 5%)

$btn-danger-color:                  #fff
$btn-danger-bg:                     #b00
$btn-danger-border:                 darken($btn-danger-bg, 5%)

// $btn-success-color:              #fff
// $btn-success-bg:                 $brand-success
// $btn-success-border:             darken($btn-success-bg, 5%)

// $btn-info-color:                 #fff
// $btn-info-bg:                    $brand-info
// $btn-info-border:                darken($btn-info-bg, 5%)

// $btn-warning-color:              #fff
// $btn-warning-bg:                 $brand-warning
// $btn-warning-border:             darken($btn-warning-bg, 5%)

// $btn-link-disabled-color:        $gray-light

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              0 !default
$btn-border-radius-sm: 0 !default
$btn-border-radius-lg: 0 !default

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d
$state-success-bg:               #dff0d8 //#5cb85c
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%)

$state-info-text:                #31708f
$state-info-bg:                  #d9edf7
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%)

$state-warning-text:                #7c653a
$state-warning-bg:                  #fff8d5
$state-warning-border:              #ffe8bc

$state-danger-text:              #a94442
$state-danger-bg:                #f2dede
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%)


//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:                 7px // $grid-min-size
$alert-padding-x:                 7px // $grid-min-size
$alert-padding-y:                 7px // $grid-min-size


//== List group
//
//##

$list-group-border:             #ddd
$list-group-active-border:      $list-group-border

$list-group-link-color:         $link-color
$list-group-link-hover-color:   $link-hover-color

//$line-height-computed: 16px !default
//todo remove on BS5
$input-height-base: 30px
$input-height-large: 34px
$input-height-small: 26px
// $padding-base-vertical: 5px

$modal-content-border-radius: 0 !default
$modal-content-inner-border-radius: 0 !default


//copied from login below, should be moved to toolbar as shared by all projecys
$input-padding-y:             .5rem !default

$input-btn-padding-y-lg:      .7rem !default

$input-border-color:                    $gray-icon !default


$btn-border-radius:           0 !default
$btn-border-radius-lg:        0 !default

$form-label-font-weight:                700 !default

$input-focus-border-color:              $brand-dark !default
$input-focus-box-shadow:                inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(85,107,124,.6) !default
$form-check-min-height:                 1.6rem !default


$link-decoration: none !default
$badge-border-radius: 10px !default
$badge-font-size: 0.75rem !default
$badge-padding-y: 0.25em !default
$badge-padding-x: 0.55em !default

$enable-caret: true !default


$dropdown-border-radius: 0 !default
$dropdown-border-color: $white !default

//todo remove as moved to css variables
$offcanvas-padding-y:               0
$offcanvas-padding-x:               0


\:root
  --bs-success-bg-subtle: #dff0d8 !important
  --bs-success-border-subtle: #d6e9c6 !important
  --bs-success-text-emphasis: #3c763d !important

  --bs-info-bg-subtle: #d9edf7 !important
  --bs-info-border-subtle: #bce8f1 !important
  --bs-info-text-emphasis: #31708f !important

  --bs-warning-bg-subtle: #fff8d5 !important
  --bs-warning-border-subtle: #ffe8bc !important
  --bs-warning-text-emphasis: #7c653a !important

  --bs-danger-bg-subtle: #f2dede !important
  --bs-danger-border-subtle: #ebccd1 !important
  --bs-danger-text-emphasis: #a94442 !important

  --bs-offcanvas-padding-y: 0 !important
  --bs-offcanvas-padding-x: 0 !important
