// Styles for linked accounts in the sidebar
#aside-linked-accounts
  .btn
    font-size: 0.875rem
  .linked-accounts-list
    list-style: none
    margin: 0
    padding: 0
  #add-account
    a:hover
      color: $brand-gray
    .ico
      font-size: 1.2rem
  .account
    &__link
      display: flex
      align-items: center
      justify-items: left
    &__avatar-wrap
      margin: 0 8px
      position: relative
      height: 2.15em
      width: 2.15em
      img, .user-initials
        display: flex
        height: inherit
        width: inherit
      .user-initials
        align-items: center
        background-color: $white
        border-radius: 50%
        color: $dark-gray
        font-weight: 600
        justify-content: center
        text-transform: uppercase

  .ipi-plus
    font-size: 1.5em
